import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false

const url = 'https://mail-api.masec-dev.ga/';

const options = {
  headers: { 'Content-Type': 'application/json'},
  mode: 'cors', credentials: 'same-origin',
};


Vue.prototype.apiURL = url; 
Vue.prototype.reqOptions = options;



new Vue({
  render: h => h(App),
}).$mount('#bacopal-newsletter-form')
